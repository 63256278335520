import colors from '@constants/colors';
import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  fill?: string;
}

// TODO replace ArrowLeft when the time comes to update all designs
const ArrowLeftNew = ({ width = 6, height = 12, fill = colors.paginationGrey, ...props }: IProps) => (
  <svg width={width} height={height} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 13L1.07071 7.07071C1.03166 7.03166 1.03166 6.96834 1.07071 6.92929L7 1"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default ArrowLeftNew;
