import { sanitizeString } from '@utils/downloadCSV';
import get from 'lodash.get';
import React from 'react';
import type * as Stitches from '@stitches/react';

import { styled } from 'stitches.config';

interface DownloadLinkProps {
  label: any;
  style?: any;
  disabled?: boolean;
  css?: Stitches.CSS;
  exportFile: (
    fileType: string,
  ) => Promise<{ columns: { label: string; key: string }[]; data: string[]; filename: string; format?: string }>;
  viewingArchived?: boolean;
  className?: string;
}

const disabledStyles = {
  textDecoration: 'none',
  cursor: 'auto',
  pointerEvents: 'none',
};

const StyledDownloadLink = styled('a', {
  textDecoration: 'none',
  variants: {
    isDisabled: {
      true: {
        textDecoration: 'none',
        cursor: 'auto',
        pointerEvents: 'none',
      },
    },
  },
});

const DownloadLink = ({
  label = 'Save',
  style,
  disabled = false,
  css,
  exportFile,
  viewingArchived,
  className,
}: DownloadLinkProps) => {
  const handleDownloadClick = (event) => {
    event.persist();
    event.preventDefault();
    function doDownload(text, fileName) {
      const sanitizedText = sanitizeString(text);
      const blob = new Blob([sanitizedText], {
        type: 'text/csv;charset=utf8;', // const type = isSafari() ? 'application/csv' : 'text/csv';
      });

      // create hidden link
      const href = window.URL.createObjectURL(blob);
      const element = document.createElement('a');
      document.body.appendChild(element);
      element.setAttribute('href', href);
      element.setAttribute('download', fileName);
      element.style.display = '';

      element.click();

      document.body.removeChild(element);
      window.URL.revokeObjectURL(href);
      event.stopPropagation();
    }

    const fileType = event.target.innerText;
    exportFile &&
      exportFile(fileType)
        .then(({ data, filename, columns }) => {
          const separator = ',';
          const enclosingCharacter = '"';
          const _data = data.map((d) => {
            return columns
              .map((col) => {
                let val = get(d, col.key);
                if (val === undefined || val === null) val = '';
                return `${enclosingCharacter}${val}${enclosingCharacter}`;
              })
              .join(separator);
          });
          _data.unshift(
            columns.map((col) => `${enclosingCharacter}${col.label || col.key}${enclosingCharacter}`).join(separator),
          );
          doDownload(_data.join('\n'), filename);
        })
        .catch(() => {
          // Do nothing, download happens elsewhere
        });
  };

  if (css) {
    return (
      <StyledDownloadLink
        isDisabled={!!disabled}
        className={className}
        href={'#'}
        css={css}
        onClick={disabled ? () => {} : handleDownloadClick}
      >
        {label}
      </StyledDownloadLink>
    );
  }

  return (
    <a
      style={disabled ? disabledStyles : style}
      className={className}
      href={'#'}
      onClick={disabled ? () => {} : handleDownloadClick}
    >
      {label}
    </a>
  );
};

export default DownloadLink;
