import colors from '@constants/colors';
import PropTypes from 'prop-types';
import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
}

const Sort = ({ width, height, fill, ...props }: IProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.3262 10.3066H7.67383C7.07471 10.3066 6.77468 9.5011 7.19831 9.03L11.5245 4.21906C11.7871 3.92703 12.2129 3.92703 12.4755 4.21906L16.8017 9.03C17.2253 9.5011 16.9253 10.3066 16.3262 10.3066Z"
      fill={fill}
    />
    <path
      d="M7.67384 13.6935H16.3262C16.9253 13.6935 17.2253 14.499 16.8017 14.9701L12.4755 19.7811C12.2129 20.0731 11.7871 20.0731 11.5245 19.7811L7.19833 14.9701C6.77466 14.499 7.07472 13.6935 7.67384 13.6935Z"
      fill={fill}
    />
  </svg>
);

Sort.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

Sort.defaultProps = {
  width: 16,
  height: 16,
  fill: colors.disabledGray,
};

const SortActiveUp = ({ width, height, fill, ...props }: IProps) => (
  <svg width={width} height={height} viewBox="0 0 10 7" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.32616 6.30664L0.673825 6.30664C0.0747077 6.30664 -0.225322 5.5011 0.198311 5.03L4.52448 0.219065C4.78708 -0.0729671 5.2129 -0.0729671 5.47551 0.219065L9.80167 5.03C10.2253 5.5011 9.92528 6.30664 9.32616 6.30664Z"
      fill={fill}
    />
  </svg>
);

SortActiveUp.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

SortActiveUp.defaultProps = {
  width: 10,
  height: 16,
  fill: colors.primaryPeacock,
};

const SortActiveDown = ({ width, height, fill, ...props }: IProps) => (
  <svg width={width} height={height} viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.67384 0.693481L9.32617 0.693481C9.92529 0.693481 10.2253 1.49902 9.80169 1.97012L5.47552 6.78106C5.21292 7.07309 4.7871 7.07309 4.52449 6.78106L0.198325 1.97012C-0.225341 1.49902 0.0747223 0.693481 0.67384 0.693481Z"
      fill={fill}
    />
  </svg>
);

SortActiveDown.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

SortActiveDown.defaultProps = {
  width: 10,
  height: 16,
  fill: colors.primaryPeacock,
};

export { Sort, SortActiveUp, SortActiveDown };
