//Admin PDP IDs
export const ADMIN_PDP_PLAN_PUBLISH_BUTTON_ID = 'Admin-PDP-Plan-Publish-Button' as const;
export const ADMIN_PDP_PLAN_EDIT_BUTTON_ID = 'Admin-PDP-Plan-Edit-Button' as const;
export const ADMIN_PDP_PROGRAM_PAYCALC_PUBLISH_BUTTON_ID = 'Admin-PDP-Program-PayCalc-Publish-Button' as const;
export const ADMIN_PLAN_CALENDAR_TABLE_EXPORT_LEAVE_DAYS_REPORT_BUTTON_ID =
  'Admin-Plan-Calendar-Table-Export-Leave-Days-Report-Button' as const;
export const ADMIN_PLAN_CALENDAR_TABLE_EXPORT_WORKING_SCHEDULE_REPORT_BUTTON_ID =
  'Admin-Plan-Calendar-Table-Export-Working-Schedule-Report-Button' as const;
export const ADMIN_PLAN_DETAILS_PAGE_SUBMIT_EDIT_BENEFIT_STATUS_MODAL_BUTTON_ID =
  'Admin-Plan-Details-Page-Submit-Edit-Benefit-Status-Modal-Button' as const;
export const ADMIN_PLAN_DETAILS_PAGE_CANCEL_EDIT_BENEFIT_DOCUMENT_TYPE_MODAL_BUTTON_ID =
  'Admin-Plan-Details-Page-Cancel-Edit-Benefit-Document-Type-Modal-Button' as const;
export const ADMIN_PLAN_DETAILS_PAGE_SUBMIT_EDIT_BENEFIT_DOCUMENT_TYPE_MODAL_BUTTON_ID =
  'Admin-Plan-Details-Page-Submit-Edit-Benefit-Document-Type-Modal-Button' as const;
export const ADMIN_PLAN_DETAILS_PAGE_CANCEL_ADD_EDIT_DENIED_TIME_MODAL_BUTTON_ID =
  'Admin-Plan-Details-Page-Cancel-Add-Edit-Denied-Time-Modal-Button' as const;
export const ADMIN_PLAN_DETAILS_PAGE_SUBMIT_ADD_EDIT_DENIED_TIME_MODAL_BUTTON_ID =
  'Admin-Plan-Details-Page-Submit-Add-Edit-Denied-Time-Modal-Button' as const;
export const ADMIN_BENEFITS_TRACKING_FMLA_DESIGNATION_MODAL_SAVE_BUTTON_ID =
  'Admin-BenefitsTracking-Fmla-Designation-Modal-Save-Button' as const;
export const ADMIN_PLAN_DETAILS_PAGE_CANCEL_EDIT_BENEFIT_STATUS_MODAL_BUTTON_ID =
  'Admin-Plan-Details-Page-Cancel-Edit-Benefit-Status-Modal-Button' as const;
export const ADMIN_PDP_TIME_OFF_CALENDAR_TIME_OFF_MODAL_SUBMIT_BUTTON_ID =
  'Admin-PDP-Time-Off-Calendar-Time-Off-Modal-Submit-Button' as const;
export const ADMIN_PDP_TIME_OFF_CALENDAR_TIME_OFF_MODAL_CANCEL_BUTTON_ID =
  'Admin-PDP-Time-Off-Calendar-Time-Off-Modal-Cancel-Button' as const;
export const ADMIN_PDP_BENEFITS_TRACKING_TAB_SAVE_BUTTON_ID = 'Admin-PDP-Benefits-Tracking-Tab-Save-Button' as const;
export const ADMIN_PDP_BENEFITS_TRACKING_TAB_ADD_DATES_BUTTON_ID =
  'Admin-PDP-Benefits-Tracking-Tab-Add-Dates-Button' as const;
export const ADMIN_PDP_BENEFITS_TRACKING_TAB_REMOVE_DATES_BUTTON_ID =
  'Admin-PDP-Benefits-Tracking-Tab-Remove-Dates-Button' as const;
export const ADMIN_PDP_PAYCALC_TAB_EDIT_BENEFIT_STATUS_FORM_SUBMIT_BUTTON_ID =
  'Admin-PDP-PayCalc-Tab-Edit-Benefit-Status-Form-Submit-Button' as const;
export const ADMIN_PDP_PAYCALC_TAB_EDIT_BENEFIT_DOCUMENT_TYPE_FORM_SUBMIT_BUTTON_ID =
  'Admin-PDP-PayCalc-Tab-Edit-Benefit-Document-Type-Form-Submit-Button' as const;
export const ADMIN_PDP_TASKS_TAB_BULK_UPDATE_TASKS_MODAL_SUBMIT_BUTTON_ID =
  'Admin-PDP-Tasks-Tab-Bulk-Update-Tasks-Modal-Submit-Button' as const;
export const ADMIN_PDP_TASKS_TAB_BULK_UPDATE_TASKS_MODAL_CANCEL_BUTTON_ID =
  'Admin-PDP-Tasks-Tab-Bulk-Update-Tasks-Modal-Cancel-Button' as const;
export const ADMIN_PDP_PAYCALC_TAB_PUBLISH_PAYCALC_OVERPAYMENT_BUTTON_ID =
  'Admin-PDP-PayCalc-Tab-Publish-PayCalc-Overpayment-Button' as const;
export const ADMIN_PDP_PAYCALC_TAB_PUBLISH_DRAFT_PAYCALC_BUTTON_ID =
  'Admin-PDP-PayCalc-Tab-Publish-Draft-PayCalc-Button' as const;
export const ADMIN_PDP_PAYCALC_TAB_RIPPLING_PUBLISH_PAYCALC_BUTTON_ID =
  'Admin-PDP-PayCalc-Tab-Rippling-Publish-PayCalc-Button' as const;

//Admin Company Detail Page (CDP) IDs
export const ADMIN_CDP_EMPLOYEES_ADD_EMPLOYEE_BUTTON_ID = 'Admin-CDP-Employees-Add-Employee-Button' as const;
export const ADMIN_CDP_EMPLOYEES_ADD_EMPLOYEE_SUBMIT_BUTTON_ID =
  'Admin-CDP-Employees-Add-Employee-Submit-Button' as const;
export const ADMIN_CDP_EMPLOYEES_ADD_EMPLOYEE_CANCEL_BUTTON_ID =
  'Admin-CDP-Employees-Add-Employee-Cancel-Button' as const;
export const ADMIN_CDP_EMPLOYEES_ADD_EMPLOYEE_DROPDOWN_ID = 'Admin-CDP-Employees-Add-Employee-Dropdown' as const;
export const ADMIN_DOCUMENT_HISTORY_DELETE_DOCUMENT_REQUEST_BUTTON_ID =
  'Admin-Document-History-Delete-Document-Request-Button' as const;

export const ADMIN_DOCUMENT_HISTORY_CANCEL_DELETE_DOCUMENT_REQUEST_BUTTON_ID =
  'Admin-Document-History-Cancel-Delete-Document-Request-Button' as const;
export const ADMIN_COMPANY_DETAILS_DOCUMENTS_TAB_CLOSE_DOCUMENT_TYPE_MODAL_BUTTON_ID =
  'Admin-Company-Details-Documents-Tab-Close-Document-Type-Modal-Button' as const;
export const ADMIN_COMPANY_DETAILS_DOCUMENTS_TAB_SUBMIT_DOCUMENT_TYPE_MODAL_BUTTON_ID =
  'Admin-Company-Details-Documents-Tab-Submit-Document-Type-Modal-Button' as const;
export const ADMIN_COMPANY_PERMISSIONS_PAGE_CLOSE_ADD_EDIT_FORM_MODAL_BUTTON_ID =
  'Admin-Company-Permissions-Page-Close-Add-Edit-Form-Modal-Button' as const;
export const ADMIN_COMPANY_PERMISSIONS_PAGE_SUBMIT_ADD_EDIT_FORM_MODAL_BUTTON_ID =
  'Admin-Company-Permissions-Page-Submit-Add-Edit-Form-Modal-Button' as const;

//Admin Document Tab, Document History Page, Document TakeOver Page IDs
export const ADMIN_DOCUMENT_HISTORY_DELETE_DOCUMENT_TYPE_BUTTON_ID =
  'Admin-Document-History-Delete-Document-Type-Button' as const;
export const ADMIN_DOCUMENT_HISTORY_CANCEL_DELETE_DOCUMENT_TYPE_BUTTON_ID =
  'Admin-Document-History-Cancel-Delete-Document-Type-Button' as const;
export const ADMIN_INTERNAL_DOCUMENT_HISTORY_DELETE_DOCUMENT_TYPE_BUTTON_ID =
  'Admin-Internal-Document-History-Delete-Document-Type-Button' as const;
export const ADMIN_INTERNAL_DOCUMENT_HISTORY_CANCEL_DELETE_DOCUMENT_TYPE_BUTTON_ID =
  'Admin-Internal-Document-History-Cancel-Delete-Document-Type-Button' as const;
export const ADMIN_DOCUMENT_HISTORY_CANCEL_DELETE_DOCUMENT_BUTTON_ID =
  'Admin-Document-History-Cancel-Delete-Document-Button' as const;
export const ADMIN_DOCUMENT_HISTORY_DELETE_DOCUMENT_BUTTON_ID =
  'Admin-Document-History-Delete-Document-Button' as const;
export const ADMIN_INTERNAL_DOCUMENT_HISTORY_DELETE_DOCUMENT_BUTTON_ID =
  'Admin-Internal-Document-History-Delete-Document-Button' as const;
export const ADMIN_INTERNAL_DOCUMENT_HISTORY_CANCEL_DELETE_DOCUMENT_BUTTON_ID =
  'Admin-Internal-Document-History-Cancel-Delete-Document-Button' as const;

//Admin Templates Page IDs
export const ADMIN_TEMPLATES_PAGE_CLOSE_UPSERT_TASK_TEMPLATE_MODAL_BUTTON_ID =
  'Admin-Templates-Page-Close-Upsert-Task-Template-Modal-Button' as const;
export const ADMIN_TEMPLATES_PAGE_SUBMIT_UPSERT_TASK_TEMPLATE_MODAL_BUTTON_ID =
  'Admin-Templates-Page-Submit-Upsert-Task-Template-Modal-Button' as const;
export const ADMIN_MANAGE_TEMPLATE_PAGE_CASCADE_EDITS_MODAL_CANCEL_BUTTON_ID =
  'Admin-Manage-Template-Page-Cascade-Edits-Modal-Cancel-Button' as const;
export const ADMIN_MANAGE_TEMPLATE_PAGE_CASCADE_EDITS_MODAL_SUBMIT_BUTTON_ID =
  'Admin-Manage-Template-Page-Cascade-Edits-Modal-Submit-Button' as const;
export const ADMIN_MANAGE_TEMPLATE_PAGE_ADD_URL_BUTTON_ID = 'Admin-Manage-Template-Page-Add-Url-Button' as const;
export const ADMIN_MANAGE_TEMPLATE_PAGE_ADD_VIDEO_LINK_BUTTON_ID =
  'Admin-Manage-Template-Page-Add-Video-Link-Button' as const;
export const ADMIN_MANAGE_TEMPLATE_PAGE_CANCEL_SAVE_TEMPLATE_BUTTON_ID =
  'Admin-Manage-Template-Page-Cancel-Save-Template-Button' as const;
export const ADMIN_MANAGE_TEMPLATE_PAGE_SAVE_TEMPLATE_BUTTON_ID =
  'Admin-Manage-Template-Page-Save-Template-Button' as const;

//Admin Super Users Page IDs
export const ADMIN_SUP_CANCEL_ADD_SUPER_USER_BUTTON_ID = 'Admin-Super-Users-Page-Cancel-Add-Super-User-Button' as const;
export const ADMIN_SUP_ADD_SUPER_USER_SUBMIT_BUTTON_ID = 'Admin-Super-Users-Page-Add-Super-User-Submit-Button' as const;

//Admin Flags Page
export const ADMIN_FLAGS_PAGE_EDIT_FLAG_MODAL_SUBMIT_BUTTON_ID =
  'Admin-Flags-Page-Edit-Flag-Modal-Submit-Button' as const;
export const ADMIN_FLAGS_PAGE_EDIT_FLAG_MODAL_CANCEL_BUTTON_ID =
  'Admin-Flags-Page-Edit-Flag-Modal-Cancel-Button' as const;

// Admin Shared Components
export const ADMIN_ADD_EDIT_COMPANY_MODAL_EXPORT_PAYROLL_REPORT_BUTTON_ID =
  'Admin-Add-Edit-Company-Modal-Export-Payroll-Report-Button' as const;
export const ADMIN_ADD_EDIT_COMPANY_MODAL_EXPORT_COMPANY_DATA_BUTTON_ID =
  'Admin-Add-Edit-Company-Modal-Export-Company-Data-Button' as const;
export const ADMIN_ADD_EDIT_COMPANY_MODAL_DELETE_COMPANY_BUTTON_ID =
  'Admin-Add-Edit-Company-Modal-Delete-Company-Button' as const;
export const ADMIN_ADD_EDIT_COMPANY_MODAL_CANCEL_BUTTON_ID = 'Admin-Add-Edit-Company-Modal-Cancel-Button' as const;
export const ADMIN_ADD_EDIT_COMPANY_MODAL_SAVE_BUTTON_ID = 'Admin-Add-Edit-Company-Modal-Save-Button' as const;
export const ADMIN_EDIT_PLAN_MODAL_CANCEL_BUTTON_ID = 'Admin-Edit-Plan-Modal-Cancel-Button' as const;
export const ADMIN_EDIT_PLAN_MODAL_SAVE_BUTTON_ID = 'Admin-Edit-Plan-Modal-Save-Button' as const;
export const ADMIN_EDIT_PLAN_MODAL_DELETE_BUTTON_ID = 'Admin-Edit-Plan-Modal-Delete-Button' as const;
