import colors from '@constants/colors';
import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  fill?: string;
}

const ChevronDownSolid = ({ width = 8, height = 5, fill = colors.paginationGrey, ...props }: IProps) => (
  <svg width={width} height={height} viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.983572 0.5H7.01404C7.43123 0.5 7.63982 1.00391 7.34451 1.29922L4.33045 4.31562C4.14763 4.49844 3.84998 4.49844 3.66717 4.31562L0.653103 1.29922C0.357791 1.00391 0.566385 0.5 0.983572 0.5Z"
      fill={fill}
    />
  </svg>
);

export default ChevronDownSolid;
