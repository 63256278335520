import colors from '@constants/colors';
import PropTypes from 'prop-types';
import React from 'react';

interface IProps {
  stroke?: string;
  size?: number;
  [key: string]: string | number;
}

const CircleQuestionMark = ({ size, stroke, ...props }: IProps) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.99998 14.6667C11.6819 14.6667 14.6666 11.6819 14.6666 8.00004C14.6666 4.31814 11.6819 1.33337 7.99998 1.33337C4.31808 1.33337 1.33331 4.31814 1.33331 8.00004C1.33331 11.6819 4.31808 14.6667 7.99998 14.6667Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.06 6.00001C6.21673 5.55446 6.5261 5.17875 6.9333 4.93944C7.3405 4.70012 7.81926 4.61264 8.28478 4.69249C8.7503 4.77234 9.17254 5.01436 9.47672 5.3757C9.78089 5.73703 9.94737 6.19436 9.94666 6.66668C9.94666 8.00001 7.94666 8.66668 7.94666 8.66668"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path d="M8 10.6666V11" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" fill="none" />
  </svg>
);

CircleQuestionMark.propTypes = {
  size: PropTypes.number,
  stroke: PropTypes.string,
};

CircleQuestionMark.defaultProps = {
  size: 16,
  stroke: colors.disabledGray,
};

export default CircleQuestionMark;
