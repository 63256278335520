import colors from '@constants/colors';
import PropTypes from 'prop-types';
import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  fill?: string;
  strokeWidth?: string;
  className?: string;
}

const ChevronDown = ({ width, height, fill, strokeWidth, ...props }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    stroke={fill}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

ChevronDown.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  strokeWidth: PropTypes.string,
};

ChevronDown.defaultProps = {
  width: 18,
  height: 18,
  fill: colors.black,
  strokeWidth: '2',
};

export default ChevronDown;
