// matches on patterns like =SUM(1,2,3) and =@A1:A2 and +FOOBAR
// skips on patterns like -$500 or +40 to avoid replacing amounts and hours adjustments
// "soft" escapes excel functions by prepending with an ', ie: '=SUM(1,2,3)
export function sanitizeString(text: string) {
  const regex = /"((?:=[a-zA-Z0-9])|((?:\+|-|@)(?![$|0-9])))/gm;
  const subst = '"\'$1';
  const result = text.replace(regex, subst);
  return result;
}

function downloadCSV(text: string, fileName: string) {
  text = `${text}` || '';

  const sanitizeText = sanitizeString(text);

  const blob = new Blob([sanitizeText], {
    type: 'text/csv;charset=utf8;', // const type = isSafari() ? 'application/csv' : 'text/csv';
  });

  // create hidden link
  const href = window.URL.createObjectURL(blob);
  const element = document.createElement('a');
  document.body.appendChild(element);
  element.setAttribute('href', href);
  element.setAttribute('download', fileName);
  element.style.display = '';

  element.click();

  document.body.removeChild(element);
  window.URL.revokeObjectURL(href);
}

export default downloadCSV;
