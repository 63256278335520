import cx from 'classnames';
import IconButton from 'components/IconButton';
import ArrowLeftNew from 'components/Icons/ArrowLeftNew';
import ArrowRight from 'components/Icons/ArrowRight';
import ChevronDownSolid from 'components/Icons/ChevronDownSolid';
import Dropdown from 'rc-dropdown';
import Menu, { MenuItem } from 'rc-menu';
import React, { useEffect, useState } from 'react';

import styles from './Pagination.module.scss';

const PAGES_INDEXES = 3;

const ITEMS_PER_PAGE = [15, 25, 50, 100];

type Props = {
  itemsPerPage: number;
  items: Record<string, unknown>[];
  paginate: (page: number, itemsPerPage: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
  hasRightMarginForHelpOverlay?: boolean;
};

const Pagination = ({ itemsPerPage, items, paginate, setItemsPerPage, hasRightMarginForHelpOverlay = true }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pages = Math.ceil(items.length / itemsPerPage);
  const selectPage = React.useCallback(
    (number) => {
      setCurrentPage(number);
      setOffset(number * itemsPerPage - (itemsPerPage - 1));
      paginate(number, itemsPerPage);
    },
    [itemsPerPage, paginate],
  );
  const [offset, setOffset] = useState(1);

  useEffect(() => {
    const _currentPages: number[] = [];

    const toLeft = Math.floor((PAGES_INDEXES - 1) / 2); // -1 is the middle
    const toRight = Math.ceil((PAGES_INDEXES - 1) / 2);
    let start = Math.max(currentPage - toLeft, 1);
    let end = Math.min(currentPage + toRight, pages);
    const remaining = PAGES_INDEXES - (end - start + 1);
    if (remaining) {
      if (start > 1) {
        start = Math.max(start - remaining, 1);
      } else {
        end = Math.min(end + toRight, pages);
      }
    }

    for (let index = start; index <= end; index++) {
      _currentPages.push(index);
    }
    if (_currentPages.length && !_currentPages.includes(currentPage)) {
      selectPage(_currentPages[_currentPages.length - 1]);
    }
  }, [currentPage, pages, selectPage]);

  const prevPage = () => {
    const prevIndexPage = currentPage - 1;
    setOffset(prevIndexPage * itemsPerPage - (itemsPerPage - 1));
    selectPage(prevIndexPage);
  };

  const nextPage = () => {
    const nextIndexPage = currentPage + 1;
    setOffset(nextIndexPage * itemsPerPage - (itemsPerPage - 1));
    selectPage(nextIndexPage);
  };

  const hasMoreLeft = currentPage > 1;
  const hasMoreRight = currentPage < pages;
  const noop = () => {};

  const getRowsPerPage = () => {
    return (
      <Menu selectable={false}>
        {ITEMS_PER_PAGE.map((num) => {
          return (
            <MenuItem
              key={num.toString()}
              value={num}
              onClick={() => setItemsPerPage(num)}
              data-testid={`pagination-dropdown-${num}`}
            >
              {num}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  return (
    <div className={cx(styles.paginationTable, !hasRightMarginForHelpOverlay && styles.noRightMargin)}>
      <span className={styles.description}>Rows per page</span>

      <Dropdown trigger={['click']} overlay={getRowsPerPage()} placement="bottomCenter">
        <span className={cx(styles.pageItem, styles.rowsPerPage)} data-testid="pagination-dropdown">
          <span className={cx(styles.description, styles.itemNumber)}>{itemsPerPage}</span>
          <IconButton
            aria-label="pagination options"
            className={styles.actionButton}
            color="lightText"
            icon={() => <ChevronDownSolid />}
          />
        </span>
      </Dropdown>

      <span className={styles.description}>{`${items.length > 0 ? offset : 0}-${Math.min(
        offset + (itemsPerPage - 1),
        items.length,
      )} of ${items.length}`}</span>
      <nav>
        <ul className={styles.pagination}>
          <li onClick={hasMoreLeft ? prevPage : noop} className={cx(styles.pageItem, styles.left)}>
            <ArrowLeftNew aria-label="Previous" />
          </li>
          <li onClick={hasMoreRight ? nextPage : noop} className={cx(styles.pageItem, styles.right)}>
            <ArrowRight aria-label="Next" />
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
