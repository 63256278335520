import colors from '@constants/colors';
import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  fill?: string;
}

const ArrowRight = ({ width = 6, height = 12, fill = colors.paginationGrey, ...props }: IProps) => (
  <svg width={width} height={height} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1 13L6.92929 7.07071C6.96834 7.03166 6.96834 6.96834 6.92929 6.92929L1 1"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default ArrowRight;
