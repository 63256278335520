import { isNullOrUndef } from './obj';

const getVal = (a) => {
  if (a === null) return '';
  switch (typeof a) {
    case 'string':
      return a.toLowerCase();
    default:
      return a;
  }
};

export function resolveProp(obj: any, path: string) {
  if (!path) return [];
  const properties = path.split('.');
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
}

export const SORT_DIR = { ascending: 1, descending: -1 };

export interface ISortConfig {
  key: string;
  direction: number;
  sort?: boolean | ((a: any, b: any) => number);
}

export function generateSorter(sortConfig: ISortConfig) {
  return (a, b) => {
    if (!sortConfig) return;
    if (sortConfig.sort instanceof Function) {
      return sortConfig.sort(a, b) * sortConfig.direction;
    }
    // default sort
    const valA = getVal(resolveProp(a, sortConfig.key));
    const valB = getVal(resolveProp(b, sortConfig.key));
    const nullA = isNullOrUndef(valA);
    const nullB = isNullOrUndef(valB);
    // JS does evaluate null < 1 to true, so here we let undefined
    // match that behavior.
    if ((nullA && !nullB) || valA < valB) {
      return sortConfig.direction === SORT_DIR.ascending ? SORT_DIR.descending : SORT_DIR.ascending;
    }
    if ((nullB && !nullA) || valA > valB) {
      return sortConfig.direction === SORT_DIR.ascending ? SORT_DIR.ascending : SORT_DIR.descending;
    }
    return 0;
  };
}

/**
 * Use this instead of a.localeCompare(b) when either a or b may be null or
 * undefined.
 * @param a any string or null or undefined
 * @param b any string or null or undefined
 * @returns a number greater than zero, less than zero, or zero indicating if
 *   argument a is greater than, less than, or equal to argument b.
 */
export function safeLocaleCompare(a: string, b: string): number {
  if (isNullOrUndef(a)) {
    return isNullOrUndef(b) ? 0 : -1;
  } else if (isNullOrUndef(b)) {
    return 1;
  }
  return a.localeCompare(b);
}
