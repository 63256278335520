import ChevronDown from '@components/Icons/ChevronDown';
import colors from '@constants/colors';
import { generateSorter, ISortConfig, resolveProp, SORT_DIR } from '@utils/sort-util';
import cx from 'classnames';
import { Button, ButtonColor } from 'components/v2';
import DownloadCSVLink from 'components/DownloadCSVLink/DownloadCSVLink';
import FeatherIcon from 'components/FeatherIcon';
import IconButton from 'components/IconButton';
import QuestionIcon from 'components/Icons/CircleQuestionMark';
import { Sort, SortActiveDown, SortActiveUp } from 'components/Icons/Sort';
import Pagination from 'components/Pagination';
import times from 'lodash/times';
import Dropdown from 'rc-dropdown';
import Menu, { MenuItem } from 'rc-menu';
import Tooltip from 'rc-tooltip';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import styles from './TableSortable.module.scss';
import {
  ADMIN_CDP_EMPLOYEES_ADD_EMPLOYEE_BUTTON_ID,
  ADMIN_CDP_EMPLOYEES_ADD_EMPLOYEE_DROPDOWN_ID,
} from '@lib/pendo/pendo-ids';

export interface DownloadProps {
  data: any[];
  columns: string[];
  filename: string;
}

interface IServerPagination {
  count: number;
  onPaginate: (pageNumber: number, itemsPerPage: number) => void;
  itemsPerPage: number;
  setItemsPerPage: (itemsPerPage: number) => void;
}

interface IActionItems {
  actionName: string;
  actionMethod: () => any;
}
interface TableSortableProps {
  data: any[];
  columns: TableColumn[];
  className?: string;
  wrapperClassName?: string;
  pagination?: any;
  viewingArchived?: boolean;
  serverPagination?: IServerPagination;
  initialSort?: ISortConfig[];
  onServerSort?: (sortConfig: ISortConfig[]) => void;
  children?: (item) => any;
  onRowClick?: (item, e) => any;
  rowActions?: (item) => any;
  actionsTooltip?: string;
  exportData?: (() => Promise<DownloadProps>) | boolean | (() => Promise<any[]>);
  headerContent?: React.ReactNode | JSX.Element;
  primaryHeader?: React.ReactNode;
  persistSortName?: string;
  actionItems?: IActionItems[];
  leftAlignActionsHeader?: boolean;
  handleItemSort?: (items: any) => void;
  includeSubTitle?: boolean;
}
export interface TableColumn {
  key: string;
  label?: string;
  sort?: ((a: any, b: any) => number) | boolean;
  onServerSort?: boolean;
  render?: (data) => any;
  subtitleRender?: (data) => any;
  renderCSV?: (data) => any;
  tooltip?: string;
  tooltipIcon?: any;
  tooltipIconProps?: any;
  className?: string;
  exportOnly?: boolean;
}
const TableSortable = (props: TableSortableProps) => {
  const {
    data,
    columns,
    className,
    wrapperClassName,
    initialSort,
    children,
    primaryHeader,
    pagination,
    viewingArchived,
    serverPagination,
    onServerSort,
    onRowClick,
    rowActions,
    actionsTooltip,
    headerContent,
    persistSortName,
    actionItems,
    leftAlignActionsHeader,
    handleItemSort,
    includeSubTitle,
  } = props;
  const exportData = props.exportData ?? false;
  const [persistedSort, setPersistedSort] = useLocalStorage(persistSortName, []);
  const [currentItems, setCurrentItems] = useState<any[]>([]);

  // use the persisted sort list if we find it, otherwise use the provided default sort
  const [sortConfig, setSortConfig] = React.useState(persistedSort.length > 0 ? persistedSort : initialSort || []);

  const sortedItems = React.useMemo(() => {
    function generateSorterFromColumn(baseConfig: ISortConfig) {
      const realConfig = { ...baseConfig };
      columns.forEach((e) => {
        if (e.key === realConfig.key && 'sort' in e) realConfig.sort = e.sort;
      });
      return generateSorter(realConfig);
    }

    if (!sortConfig || sortConfig.length === 0) return data;
    const sortableItems = [...data];
    [...sortConfig].reverse().forEach((config) => sortableItems.sort(generateSorterFromColumn(config)));

    // NOTE: this function passes the sorted table items to a parent
    handleItemSort && handleItemSort(sortableItems);
    return sortableItems;
  }, [data, sortConfig, columns]);

  const requestSort = useCallback(
    (key: string) => {
      let direction = SORT_DIR.ascending;
      if (sortConfig?.length > 0 && sortConfig[0].key === key && sortConfig[0].direction === SORT_DIR.ascending) {
        // toggle
        direction = SORT_DIR.descending;
      }
      const newSortConfig = sortConfig.reduce(
        (cfgs, next) => {
          if (cfgs.findIndex((cfg) => cfg.key === next.key) < 0) cfgs.push(next);
          return cfgs;
        },
        [{ key, direction }],
      );
      setSortConfig(newSortConfig);
      // persist new config
      if (persistSortName?.trim()) setPersistedSort(newSortConfig);
    },
    [persistSortName, setPersistedSort, sortConfig],
  );

  const getPlansActionsMenu = () => {
    return (
      <Menu selectable={false}>
        {actionItems.map((item) => {
          return (
            <MenuItem
              id={item.actionName === 'Add Employee' ? ADMIN_CDP_EMPLOYEES_ADD_EMPLOYEE_BUTTON_ID : undefined}
              key={`action-dropdown.${item.actionName}`}
              className={styles.actionMenuItem}
              onClick={item.actionMethod}
            >
              {item.actionName}
            </MenuItem>
          );
        })}
        {exportData && (
          <MenuItem disabled={!data && data.length === 0} className={styles.actionMenuItem}>
            <DownloadCSVLink
              disabled={!data && data.length === 0}
              exportFile={handleDownloadCSV as any}
              style={{ all: 'unset' }}
              label={'Export Report'}
            />
          </MenuItem>
        )}
      </Menu>
    );
  };
  // no sorting
  useEffect(() => {
    if (!pagination) setCurrentItems(data);
    setCurrentItems(data);
  }, [data, setCurrentItems, pagination]);

  // local (non-server) sorting
  useEffect(() => {
    if (!currentItems || !pagination || onServerSort) return;
    const { page, itemsPerPage } = pagination;
    const indexOfLastItem = page * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setCurrentItems(sortedItems.slice(indexOfFirstItem, indexOfLastItem));
  }, [sortedItems, pagination, onServerSort]);

  // server sorting
  useEffect(() => {
    if (!onServerSort) return;
    setCurrentItems(data);
  }, [data, onServerSort, setCurrentItems]);

  // server sorting
  useEffect(() => {
    if (!onServerSort) return;
    onServerSort(sortConfig);
  }, [onServerSort, sortConfig]);

  const renderColumns = useCallback(
    (props: TableSortableProps) => {
      const allCols = [...columns];
      if (props.rowActions) {
        allCols.push({
          key: `acts`,
          label: 'Actions',
          className: !leftAlignActionsHeader ? styles.lastColumn : '',
          tooltip: actionsTooltip,
          tooltipIcon: FeatherIcon,
          tooltipIconProps: { name: 'AlertCircle', size: 16, color: colors.disabledGray, className: styles.noFill },
        });
      }

      return allCols.map((column, idx) => {
        // build the class names for the column; the use of a key appears to
        // prevent some weird race condition with displaying the indicator for the
        // initial sort
        let className = `${column.key}`;
        if (column.className) className += ` ${column.className}`;
        if (column.sort || column.onServerSort) className += ` ${styles.sortable}`;
        if (!column.exportOnly) {
          return (
            <th
              key={`${column.key}-${idx}`}
              className={className}
              onClick={column.sort ? () => requestSort(column.key) : undefined}
            >
              <span className={column.className !== styles.lastColumn ? styles.sortTitle : styles.lastColumnSpan}>
                {column.label ?? column.key}
                {column.tooltip && (
                  // TODO ENG-1736
                  // For now we need to combo the correct class styles with the tooltip
                  // based on its position to correctly color the tooltip arrow.
                  // Ideally we should wrap this peculiarity in a component to abstract away
                  // the issue. A follow up ticket should do this and replace all the tooltips
                  // in our app to use the rc-tooltip component
                  <Tooltip
                    key={'tooltip-' + column.key}
                    placement={'top'}
                    trigger={['click']}
                    overlayClassName={cx(styles.darkTooltip, styles.top)}
                    overlay={<span>{column.tooltip}</span>}
                  >
                    <IconButton
                      icon={column.tooltipIcon || QuestionIcon}
                      iconProps={column.tooltipIconProps}
                      className={styles.tooltipIcon}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Tooltip>
                )}
              </span>
              {(column.sort || column.onServerSort) &&
                (sortConfig?.length > 0 && sortConfig[0].key === column.key ? (
                  sortConfig[0].direction === SORT_DIR.ascending ? (
                    <SortActiveUp className={styles.sortIcon} data-testid={`${column.key}-sort-up`} />
                  ) : (
                    <SortActiveDown className={styles.sortIcon} data-testid={`${column.key}-sort-down`} />
                  )
                ) : (
                  <Sort className={styles.sortIcon} />
                ))}
            </th>
          );
        }
      });
    },
    [columns, leftAlignActionsHeader, sortConfig, requestSort],
  );

  const handleDownloadCSV = async () => {
    let data;
    let columns;
    let filename = `export-${new Date().toLocaleDateString()}.csv`;
    if (exportData === false) return;
    if (serverPagination && typeof exportData === 'function') {
      const allData = await (exportData as any)();
      data = getCSVDataFromProps(allData);
      columns = props.columns;
    } else if (exportData === true) {
      data = getCSVDataFromProps();
      columns = props.columns;
    } else {
      const d = await (exportData as any)();
      data = d.data;
      columns = d.columns;
      filename = d.filename || filename;
    }
    return { data, columns, filename };
  };

  const getCSVDataFromProps = (data = props.data) => {
    return data.map((row) => {
      const data = props.columns.reduce((acc, col) => {
        if (col.renderCSV) {
          acc[col.key] = col.renderCSV(row);
        } else if (col.render) {
          acc[col.key] = col.render(row);
        } else {
          acc[col.key] = resolveProp(row, col.key);
        }
        return acc;
      }, {});
      return data;
    });
  };

  return (
    <>
      {primaryHeader || null}
      {exportData || headerContent ? (
        <div className={headerContent ? styles.headerTable : cx(styles.headerTable, styles.noContent)}>
          {headerContent ? headerContent : null}
          {actionItems ? (
            <Dropdown trigger={['click']} overlay={getPlansActionsMenu} animation="slide-up" placement="bottomRight">
              <Button
                color={ButtonColor.OUTLINE}
                type="button"
                className={styles.actionsButton}
                css={{ gap: '$8' }}
                id={ADMIN_CDP_EMPLOYEES_ADD_EMPLOYEE_DROPDOWN_ID}
              >
                Actions
                <ChevronDown height={22} width={22} className={styles.actionChevron} />
              </Button>
            </Dropdown>
          ) : null}
          {exportData && !actionItems ? (
            <DownloadCSVLink
              disabled={!data && data.length === 0}
              exportFile={handleDownloadCSV as any}
              viewingArchived={viewingArchived}
              label={
                <Button color={ButtonColor.OUTLINE} type="button" disabled={!data && data.length === 0}>
                  Export Report
                </Button>
              }
            />
          ) : null}
        </div>
      ) : null}
      <div className={cx(styles.tableWrapper, wrapperClassName)}>
        <table className={cx(styles.table, className)}>
          <thead>
            <tr>{columns && renderColumns(props)}</tr>
          </thead>
          <tbody>
            {currentItems?.map((row, ix) => {
              if (children) return children(row); // custom renderer
              const onClick = onRowClick ? (e) => onRowClick(row, e) : undefined;
              return (
                <tr className={onRowClick ? styles.clickable : ''} key={ix} onClick={onClick}>
                  {columns
                    .filter((col) => !col.exportOnly)
                    .map((col, idx) => {
                      if (includeSubTitle) {
                        return (
                          <td key={`${col.key}-${idx}`} className={styles.email}>
                            <span className={styles.mobileOnlyTitle}>{col.label ?? col.key}: </span>
                            {col.render ? col.render(row) : resolveProp(row, col.key)}
                            {col.subtitleRender && <div className={styles.subHeader}>{col.subtitleRender(row)}</div>}
                          </td>
                        );
                      }
                      return (
                        <td key={`${col.key}-${idx}`} className={styles.email}>
                          <span className={styles.mobileOnlyTitle}>{col.label ?? col.key}: </span>
                          {col.render ? col.render(row) : resolveProp(row, col.key)}
                        </td>
                      );
                    })}
                  {rowActions ? (
                    <td>
                      <span className={styles.rowActions}>{rowActions(row)}</span>
                    </td>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {serverPagination && (
        <Pagination
          itemsPerPage={serverPagination.itemsPerPage}
          items={times(serverPagination.count, () => ({
            placeholder: true,
          }))}
          setItemsPerPage={serverPagination?.setItemsPerPage}
          paginate={serverPagination.onPaginate}
        />
      )}
      {pagination && (
        <Pagination
          itemsPerPage={pagination.itemsPerPage}
          setItemsPerPage={pagination.setItemsPerPage}
          items={data}
          paginate={pagination.paginate}
        />
      )}
    </>
  );
};

export default TableSortable;
