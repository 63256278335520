import { useState } from 'react';

const usePagination = (
  defaultItemsPerPage = 15,
): {
  paginate: (num, itemsPer) => void;
  page: number;
  itemsPerPage: number;
  setItemsPerPage: (num) => void;
  setPage: (num) => void;
} => {
  const [page, setPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(defaultItemsPerPage);
  const paginate = (num, itemsPer) => {
    setPage(num);
    setItemsPerPage(itemsPer);
  };
  return {
    paginate,
    page,
    itemsPerPage,
    setItemsPerPage,
    setPage,
  };
};

export default usePagination;
